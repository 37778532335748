<template>
    <v-container fluid>
        <v-row>
            <v-col 
                cols="7"
                lg="10"
                md="10"
                sm="6"
            >
                <h3>{{ $t('pedidoPorStatus') }}</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col 
                cols="12"
                lg="11"
                md="10"
                sm="12"
            >
                <label>{{ $t('status') }}</label>
                <v-combobox
                    v-model="statusSelected"
                    :items="listStatus"
                    item-text="statusDescricao"
                    item-value="id"
                    clearable
                    outlined
                    @change="getRegisters()"
                ></v-combobox>
            </v-col>
            <v-col 
                style="display: flex; align; align-content: center; align-items: center;"
                cols="12"
                lg="1"
                md="2"
                sm="12"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <div class="exportButtons">
                            <v-btn 
                                style="width: 45px; border: none !important;"
                                class="btnActions"
                                outlined
                                v-on="on"
                                color="var(--color__main) !important"
                                small
                                @click="exportar()"
                            >
                                <v-icon v-on="on" large>mdi mdi-file-excel-outline</v-icon>
                            </v-btn>
                            <label style="font-size: 12px;">{{ $t('exportar') }}</label>
                        </div>
                    </template>
                    <span>{{ $t('exportarExcel') }}</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :options.sync="options"
                    :loading="loading"
                    :items="listRelatorio"
                    :items-per-page="30"
                    :no-data-text="$t('nenhumRegistroEncontrado')"
                    :footer-props="{
                        'items-per-page-text':$t('registrosPorPagina'),
                        'items-per-page-all-text':$t('todos'),
                        pageText: '{0}-{1} de {2}'
                    }"
                >
                    <template v-slot:item.statusDescricao="{ item }">
                        <v-chip
                            :color="getStatusColor(item.status)"
                            outlined
                            dark
                        >
                            {{ item.statusDescricao }}
                        </v-chip>
                    </template>

                </v-data-table>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';

    export default ({

        mixins: [Helpers],

        data: vue => ({

            loading: false,

            headers: [
                { text: vue.$t('criadoEm'), value: "dataCadastroFormatted", sortable: true },
                { text: vue.$t('marca'), value: "marcaDescricao", sortable: true },
                { text: vue.$t('condicaoPagamento'), value: "condicaoPagamentoDescricao", sortable: true },
                { text: vue.$t('formaPagamento'), value: "formaPagamentoDescricao", sortable: true },
                { text: vue.$t('frete'), value: "valorFreteFormatted", sortable: false, align: "right" },
                { text: vue.$t('valor'), value: "valorTotalFormatted", sortable: false, align: "right" },
                { text: vue.$t('numeroPedido'), value: "codigoPedidoTotvs", sortable: true, align: "left" },
                { text: vue.$t('status'), value: "statusDescricao", sortable: true }
            ],

            options: { rowsPerPage: 30, page: 1 },
            
            statusSelected: null,
            listStatus: [],
            listRelatorio: [],
        }),

        methods: {

            getStatusColor (status) {

                let color = "";

                switch (status) {

                    case 1: //ATIVO
                        color = 'var(--color__main)';
                        break;
                        
                    case 0: //INATIVO
                        color = 'var(--color__status_inactive)';
                        break;

                    case 2: //NEGADO
                        color = 'var(--color__red)';
                        break;

                    case 3: //AGUARDANDO INTEGRAÇÃO
                        color = 'var(--color__blue)';
                        break;

                    case 4: //PEDIDO INTEGRADO NO TOTVS
                        color = 'var(--color__main)';
                        break;

                    case 5: //ERRO NA INTEGRAÇÃO COM O TOTVS
                        color = 'var(--color__red)';
                        break;

                    case 6: //EM PROCESSAMENTO
                        color = 'var(--color__warning)';
                        break;
                
                    default:
                        color = 'var(--color__main)';
                        break;
                }

                return color
            },

            async getLists() {
                this.listStatus = await this.$store.dispatch("pedidoModule/ListStatus");
                this.statusSelected = this.listStatus[0];
            },

            async getRequest() {

                let pedidoPorStatusRequest = {
                    status: 0
                }

                if (this.statusSelected != null && this.statusSelected != undefined) {
                    pedidoPorStatusRequest.status = this.statusSelected.id;
                }

                return pedidoPorStatusRequest;
            },

            async getRegisters() {
                const request = await this.getRequest();

                this.listRelatorio = await this.$store.dispatch("relatoriosModule/ListPedidosPorStatus", request);
            },

            async exportar() {
                
                this.showLoading();

                const request = await this.getRequest();

                this.$spTechApi.post(`relatorio/pedidos-por-status-exportar`, request, {
                        responseType: "blob",
                    })
                    .then((response) => {

                        var blob = new Blob([response.data], {
                            type: "application/octet-stream",
                        });

                        var fileName = 'RelatorioPedidosPorStatus.xlsx';

                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement("a");

                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.download = fileName;
                        a.click();
                        window.URL.revokeObjectURL(url);

                        this.hideLoading();
                    })
                    .catch(error => {
                        
                        let errorMessage = "";
                        if (error.response && error.response.status === 400) {
                            errorMessage = `${this.$t('erroAoExportar')}: [${error.response.data}]`;
                        }
                        else {
                            errorMessage = `${this.$t('erroAoExportar')}: [${error}]`;
                        }
                        
                        this.showToast("error", this.$t('erro'), errorMessage);
                        this.hideLoading();
                    }
                );
            },
        },

        async created() {
            this.showLoading();

            await this.getLists();
            await this.getRegisters();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .exportButtons {
        display: flex; 
        flex-direction: column; 
        align-items: center;
    }
</style>