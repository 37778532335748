<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12">
                    <h3>{{ $t('minhasInformacoes') }}</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="3"
                    md="4"
                    sm="12"
                >                    
                    <v-radio-group 
                        row 
                        v-model="userLoggedGetters.corTema" 
                        @change="changeTheme()"
                        style="width: 100%"
                    >
                        <v-radio :label="$t('escuro')" :value="1" color="var(--color__main)"></v-radio>
                        <v-radio :label="$t('claro')" :value="2" color="var(--color__main)"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="3"
                    md="4"
                    sm="12"
                >
                    <v-switch 
                        v-model="userLoggedGetters.receberNotificacaoEmail"
                        :label="$t('receberNotificacaoEmail')"
                        :value=1
                        color="var(--color__main)"
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>{{ $t('primeiroNome') }}</label>
                    <v-text-field
                        v-model="userLoggedGetters.primeiroNome" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>{{ $t('ultimoNome') }}</label>
                    <v-text-field
                        v-model="userLoggedGetters.ultimoNome" 
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>{{ $t('email') }}</label>
                    <v-text-field
                        v-model="userLoggedGetters.email"
                        single-line
                        disabled
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                >
                    <label>{{ $t('telefone') }}</label>
                    <v-text-field
                        v-model="userLoggedGetters.telefone" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <br />

            <ActionButtons
                :request="userLoggedGetters"
                :showButtons="{
                    cancel: false,
                    save: true
                }"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        data: () => ({

            validForm: true,

            validations: {
                required: required,
            }
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),
        },

        methods: {

            changeTheme() {
                
                if (this.userLoggedGetters.corTema === 1)  { 
                    //dark
                    this.$vuetify.theme.dark = true;
                }
                else { 
                    // light
                    this.$vuetify.theme.dark = false;
                }
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", this.$t('aviso'), this.$t('inconsistenciaFormulario'));
                    return false;
                }
                else {

                    this.loading = true;
                    this.showLoading();

                    let receberNotificacaoEmail = 1;

                    if (this.userLoggedGetters.receberNotificacaoEmail === null) {
                        receberNotificacaoEmail = 0;
                    }

                    const requestProfile = {
                        idUsuario: this.userLoggedGetters.id, 
                        corTema: this.userLoggedGetters.corTema,
                        telefone: this.userLoggedGetters.telefone,
                        receberNotificacaoEmail
                    }

                    const result = await this.$store.dispatch("usuarioModule/AtualizarPerfil", requestProfile);

                    if (result.success === true) {
                        this.showToast("success", this.$t('sucesso'), result.message);
                    }
                    else {
                        this.showToast("error", this.$t('aviso'), result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            }
        },

        created() {
            this.showLoading();

            this.hideLoading();
        }
    })
</script>
