<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>{{ $t('unidadeMedida') }}</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="3"
                    lg="3"
                    md="3"
                    sm="3"
                >
                    <label>{{ $t('sigla') }}</label>
                    <v-text-field
                        v-model="unidadeMedidaRequest.sigla" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="9"
                    lg="9"
                    md="9"
                    sm="9"
                >
                    <label>{{ $t('descricao') }}</label>
                    <v-text-field
                        v-if="lang == 'pt'"
                        v-model="unidadeMedidaRequest.descricao_Pt" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                        <template #prepend-inner>
                            <v-img
                                :src="require('@/assets/images/brasil-flag.png')" max-height="25" max-width="25" 
                                class="custom-icon"
                                @click="switchLang('es')"
                            />
                        </template>
                    </v-text-field>
                    <v-text-field
                        v-else
                        v-model="unidadeMedidaRequest.descricao_Es" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                        <template #prepend-inner>
                            <v-img
                                :src="require('@/assets/images/espanha-flag.png')" max-height="25" max-width="25" 
                                class="custom-icon"
                                @click="switchLang('pt')"
                            />
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            
            <br />

            <ActionButtons
                :request="unidadeMedidaRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        data: () => ({

            validForm: true,
            lang: 'pt',

            unidadeMedidaRequest: {
                id: 0,
                descricao: ""
            },

            loading: false,
            
            validations: {
                required: required,
            }
        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {
            
            async getRegister() {

                if (this.id !== 0) {

                    const response = await this.$store.dispatch("unidadeMedidaModule/GetById", this.id);

                    if (response.success) {
                        this.unidadeMedidaRequest = response.result;
                    }

                    this.lang = this.getLanguage();

                }
                else {
                    this.unidadeMedidaRequest.status = 1;
                }
            },

            async cancel() {
                this.$router.push({ path: "/produto/unidadeMedidaList" });
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", this.$t('aviso'), this.$t('inconsistenciaFormulario'));
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    this.unidadeMedidaRequest.descricao = this.unidadeMedidaRequest.descricao_Pt;
                    this.unidadeMedidaRequest.id = this.id;

                    const result = await this.$store.dispatch("unidadeMedidaModule/CreateUpdate", this.unidadeMedidaRequest);

                    if (result.success === true) {
                        this.showToast("success", this.$t('sucesso'), result.message);
                        this.cancel();
                    }
                    else {
                        this.showToast("error", this.$t('aviso'), result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            },

            switchLang(lang) {
                this.lang = lang;
            }
        },

        created() {
            this.showLoading();

            this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .colButtonList {
        padding-top: 20px;    
    }

    .colButtonList2 {
        margin-top: 8px;
    }

    @media only screen and (max-width: 647px) {
        .colButtonList {
            margin-top: -30px;
            display: flex;
            align-items: center;
        }

        .colButtonList2 {
             margin-top: -8px;
        }

        .btnActions {
            margin-bottom: 5px;
        }
    }
</style>