var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","lg":"10","md":"8","sm":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('cambio')))])]),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":{ name: 'cambioForm', params: {id: 0} }}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('novo'))+" "+_vm._s(_vm.$t('cambio'))+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('novo'))+" "+_vm._s(_vm.$t('cambio')))])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.getRegisters($event)}}},[_c('v-text-field',{attrs:{"label":_vm.$t('pesquisaRapida'),"append-outer-icon":"mdi mdi-magnify","single-line":"","outlined":""},on:{"click":function($event){_vm.options.page=1;},"click:append-outer":_vm.getRegisters},model:{value:(_vm.filter.fastSearch),callback:function ($$v) {_vm.$set(_vm.filter, "fastSearch", $$v)},expression:"filter.fastSearch"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredSearch,"options":_vm.options,"loading":_vm.loading,"items-per-page":10,"no-data-text":_vm.$t('nenhumRegistroEncontrado'),"footer-props":{
                    'items-per-page-text':_vm.$t('registrosPorPagina'),
                    'items-per-page-all-text':_vm.$t('todos'),
                    pageText: '{0}-{1} de {2}'
                }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('ActionList',{attrs:{"id":item.id,"link":'cambioForm',"showButtons":{
                            edit: true,
                        }}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }