<template>
    <v-container fluid>
        <v-row>
            <v-col 
                cols="6"
                lg="10"
                md="8"
                sm="6"
            >
                <h3>{{ $t('formaPagamento') }}</h3>
            </v-col>
            <v-col 
                cols="2" 
                style="text-align: right;"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            :to="{ name: 'formaPagamentoForm', params: {id: 0} }"
                        >
                            {{ $t('novo') }} {{ $t('formaPagamento') }}
                        </v-btn>
                    </template>
                    <span>{{ $t('novo') }} {{ $t('formaPagamento') }}</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        :label="$t('pesquisaRapida')"
                        append-outer-icon="mdi mdi-magnify"
                        single-line
                        outlined
                        @click="options.page=1;"
                        @click:append-outer="getRegisters"
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    :no-data-text="$t('nenhumRegistroEncontrado')"
                    :footer-props="{
                        'items-per-page-text':$t('registrosPorPagina'),
                        'items-per-page-all-text':$t('todos'),
                        pageText: '{0}-{1} de {2}'
                    }"
                >

                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id" 
                            :link="'formaPagamentoForm'"
                            :showButtons="{
                                edit: true,
                                delete: true,
                            }"
                            @confirmDelete="confirmDelete" />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionDialog from "@/components/Layout/ActionDialog";

    export default ({

        components: {
            ActionList: ActionList,
            ActionDialog: ActionDialog
        },

        mixins: [Helpers],

        data: vue => ({

            loading: false,

            statusId: 0,

            filter: {
                fastSearch: ''
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: vue.$t('descricao'), value: "descricao", sortable: true, size: "85%" },
                { text: vue.$t('acoes'), value: "action", sortable: false, align: "center" }
            ],

            options: { rowsPerPage: 10, page: 1 },

            listFormaPagamento: []
        }),

        computed: {

            filteredSearch: function() {
                return this.listFormaPagamento.filter((formaPagamentoFilter) => {
                    
                    let filter = this.filter.fastSearch.toLowerCase();

                    var descricao = formaPagamentoFilter.descricao.toLowerCase().match(filter)
                    var id = formaPagamentoFilter.id.toString().match(filter);

                    if(descricao != null) { return descricao; }
                    else { return id; }
                });
            }
        },

        methods: {
            
            async getRegisters() {

                this.listFormaPagamento = await this.$store.dispatch("formaPagamentoModule/List");
            },

            confirmDelete(id) {

                this.dialog = {
                    show: true,
                    headerText: this.$t('confirmacao'),
                    bodyText: this.$t('perguntaExclusaoFormaPagamento'),
                    methodConfirm: this.delete,
                    params: id
                };
            },

            async delete(id) {

                this.showLoading();

                let result = await this.$store.dispatch("formaPagamentoModule/Delete", id);

                if (result.success) {
                    this.showToast("success", this.$t('sucesso'), result.message);
                }
                else {
                    this.showToast("error", this.$t('erro'), result.message);
                }

                this.getRegisters();

                this.hideLoading();
            },
        },

        async created() {
            this.showLoading();

            await this.getRegisters();

            this.hideLoading();
        }
    })
</script>
