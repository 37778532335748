import { 
    showDialog, 
    showToast, 
    showLoading, 
    hideLoading, 
    openSideMenu, 
    getLanguage, 
    showFlags 
} from './Utils' //busca da diretiva v-formatMoney

export default {

    methods: {

        scrollTo: function (position) {
            var options = {
                duration: 700,
                offset: 0,
                easing: 'easeInOutCubic'
            };
            this.$vuetify.goTo(position, options);
        },

        showLoading: function () {
            showLoading();
        },

        hideLoading: function () {
            hideLoading();
        },

        openSideMenu: function (open) {
            openSideMenu(open);
        },

        showDialog: function (text, func, params) {
            showDialog(text, func, params);
        },

        showToast: function (state, title, content) {
            showToast(state, title, content);
        },

        formatTooltipHelper: function (mdiIcon, message, complement) {
            if (message === '' && complement === '')
                return '';

            return `<i class='mdi mdi-${mdiIcon} mdi-36px'></i><p class='tooltip-simple'>${message}</p><p class='tooltip-content'>${complement}</p>`;
        },

        formatarApenasNumero: function (data) {
            return data.replace(/\D/g, '')
        },

        virgulaPorPonto: function (data) {
            if (data.length <= 0)
                return data;

            if (data.toString().search(",") <= 0)
                return data;

            return data.replace(/,/g, '.');
        },

        pontoPorVirgula: function (data) {
            if (data.length <= 0)
                return data;

            return data.toString().replace(/\./g, ',')
        },

        removerPonto: function (data) {
            if (data.length <= 0)
                return data;

            return data.toString().replace(/\./g, '');
        },

        formatarPositivoDecimal: function (data) {
            return Math.abs(this.virgulaPorPonto(this.removerPonto(data))).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        },

        cloneObject: function (object) {
            return JSON.parse(JSON.stringify(object));
        },
        
        isCpf: function (cpf) {

            cpf = cpf.replace(/[^\d]+/g, '');

            if (cpf === "00000000000" || cpf === "11111111111" || cpf === "22222222222" || cpf === "33333333333"
                || cpf === "44444444444" || cpf === "55555555555" || cpf === "66666666666" || cpf === "77777777777"
                || cpf === "88888888888" || cpf === "99999999999"
            ) return false;

            if (cpf.length !== 11) return false;

            var Soma = 0;
            var Resto;
            var i;

            for (i = 1; i <= 9; i++) Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
            Resto = (Soma * 10) % 11;

            if ((Resto == 10) || (Resto == 11)) Resto = 0;
            if (Resto != parseInt(cpf.substring(9, 10))) return false;

            Soma = 0;
            for (i = 1; i <= 10; i++) Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
            Resto = (Soma * 10) % 11;

            if ((Resto == 10) || (Resto == 11)) Resto = 0;
            if (Resto != parseInt(cpf.substring(10, 11))) return false;

            return true;
        },

        isCnpj: function (cnpj) {
            cnpj = cnpj.replace(/[^\d]+/g, '');

            if (cnpj == '') return false;

            if (cnpj.length != 14)
                return false;

            // Elimina CNPJs invalidos conhecidos
            if (cnpj === "00000000000000" ||
                cnpj === "11111111111111" ||
                cnpj === "22222222222222" ||
                cnpj === "33333333333333" ||
                cnpj === "44444444444444" ||
                cnpj === "55555555555555" ||
                cnpj === "66666666666666" ||
                cnpj === "77777777777777" ||
                cnpj === "88888888888888" ||
                cnpj === "99999999999999")
                return false;

            // Valida DVs
            var tamanho = cnpj.length - 2
            var numeros = cnpj.substring(0, tamanho);
            var digitos = cnpj.substring(tamanho);
            var soma = 0;
            var pos = tamanho - 7;
            var i;

            for (i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(0))
                return false;

            tamanho = tamanho + 1;
            numeros = cnpj.substring(0, tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }

            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

            if (resultado != digitos.charAt(1))
                return false;

            return true;
        },

        obterParametrosURL() {
            var vars = {};
            window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
                vars[key] = value;
            });

            return vars;
        },

        getLanguage() {
            return getLanguage();
        },

        showFlags() {
            return showFlags();
        }
    }
}