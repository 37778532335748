<template>
    <v-container fluid>
        <v-row>
            <v-col 
                cols="6"
                lg="10"
                md="8"
                sm="6"
            >
                <h3>{{ $t('empresa') }}</h3>
            </v-col>
            <v-col 
                cols="2" 
                style="text-align: right;"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            :to="{ name: 'empresaForm', params: {id: 0} }"
                        >
                            {{ $t('novo') }} {{ $t('empresa') }}
                        </v-btn>
                    </template>
                    <span>{{ $t('novo') }} {{ $t('empresa') }}</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        :label="$t('pesquisaRapida')"
                        append-outer-icon="mdi mdi-magnify"
                        single-line
                        outlined
                        @click="options.page=1;"
                        @click:append-outer="getRegisters"
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    :no-data-text="$t('nenhumRegistroEncontrado')"
                    :footer-props="{
                        'items-per-page-text':$t('registrosPorPagina'),
                        'items-per-page-all-text':$t('todos'),
                        pageText: '{0}-{1} de {2}'
                    }"
                >
                
                    <template v-slot:item.statusDescription="{ item }">
                        <v-chip
                            :color="getStatusColor(item.status)"
                            outlined
                            dark
                        >
                            {{ item.statusDescription }}
                        </v-chip>
                    </template>

                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id" 
                            :link="'empresaForm'"
                            :showButtons="{
                                edit: true,
                                delete: false,
                                cancel: item.status === 1 ? true : false,
                                active: item.status === 0 ? true : false
                            }"
                            @confirmCancel="confirmCancel"
                            @confirmActive="confirmActive" />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionDialog from "@/components/Layout/ActionDialog";

    export default ({

        components: {
            ActionList: ActionList,
            ActionDialog: ActionDialog
        },

        mixins: [Helpers],

        data: vue => ({

            loading: false,

            statusId: 0,

            filter: {
                fastSearch: ''
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: vue.$t('cnpj'), value: "cnpj", sortable: true },
                { text: vue.$t('razaoSocial'), value: "razaoSocial", sortable: true },
                { text: vue.$t('status'), value: "statusDescription", sortable: true },
                { text: vue.$t('acoes'), value: "action", sortable: false, align: "center" }
            ],

            options: { rowsPerPage: 10, page: 1 },

            listEmpresa: []
        }),

        computed: {

            filteredSearch: function() {
                return this.listEmpresa.filter((empresaFilter) => {
                    
                    let filter = this.filter.fastSearch.toLowerCase();

                    var cnpj = empresaFilter.cnpj.toLowerCase().match(filter)
                    var razaoSocial = empresaFilter.razaoSocial.toLowerCase().match(filter)
                    var id = empresaFilter.id.toString().match(filter);

                    if(cnpj != null) { return cnpj; } 
                    else if(razaoSocial != null) { return razaoSocial; } 
                    else { return id; }
                });
            }
        },

        methods: {

            getStatusColor (statusDescription) {

                let color = "";

                switch (statusDescription) {
                    
                    //ATIVAR
                    case 1:
                        color = 'var(--color__main)';
                        break;

                    //INATIVAR 
                    case 0:
                        color = 'var(--color__status_inactive)';
                        break;
                
                    default:
                        break;
                }

                return color
            },
            
            async getRegisters() {

                this.listEmpresa = await this.$store.dispatch("empresaModule/List");
            },

            confirmCancel(id) {

                this.statusId = 0;

                this.dialog = {
                    show: true,
                    headerText: this.$t('confirmacao'),
                    bodyText: this.$t('perguntaInativacaoEmpresa'),
                    methodConfirm: this.cancel,
                    params: id
                };
            },

            async cancel(id) {

                let statusId = this.statusId;

                this.showLoading();

                let result = await this.$store.dispatch("empresaModule/AlterarStatus", { id, statusId });

                if (result.success) {
                    this.showToast("success", this.$t('sucesso'), result.message);
                }
                else {
                    this.showToast("error", this.$t('erro'), result.message);
                }

                this.getRegisters();

                this.hideLoading();
            },

            confirmActive(id) {

                this.statusId = 1;

                this.dialog = {
                    show: true,
                    headerText: this.$t('confirmacao'),
                    bodyText: this.$t('perguntaAtivacaoEmpresa'),
                    methodConfirm: this.active,
                    params: id
                };

            },

            async active(id) {

                this.showLoading();
                const statusId = this.statusId;

                const result = await this.$store.dispatch("empresaModule/AlterarStatus", { id, statusId });

                if (result.success) {
                    this.showToast("success", this.$t('sucesso'), result.message);
                }
                else {
                    this.showToast("error", this.$t('erro'), result.message);
                }

                this.getRegisters();

                this.hideLoading();
            }
        },

        async created() {
            this.showLoading();

            await this.getRegisters();

            this.hideLoading();
        }
    })
</script>
