
import Vue from 'vue'
import { campanhaCreditoFormatServer } from "./campanhaCreditoFormatServer";
import i18n from '@/locales/_i18n';

const getDefaultState = () => {
    return {
        campanhaCredito: {
            id: 0,
            descricao: "",
            valor: 0,
            desconto: 0
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List() {

        try {
            const result = await Vue.prototype.$spTechApi.get("campanha/credito/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listCampanha = result.data.result;

                return listCampanha;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar campanha de credito: [${ex}]`
            };
        }
    },

    async ListByEstado(_, estado) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`campanha/credito/list-by-estado/${estado}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listCampanha = result.data.result;

                return listCampanha;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar campanha de crédito: [${ex}]`
            };
        }
    },

    async ConsultarParticipacao(_, campanhaCreditoParticipacaoFilter) {

        try {
            const result = await Vue.prototype.$spTechApi.post("campanha/credito/consultar-participacao", campanhaCreditoParticipacaoFilter,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listCampanha = result.data.result;

                return listCampanha;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao consultar participação em campanha: [${ex}]`
            };
        }
    },

    async ConsultarParticipacaoPorID(_, idDistribuidor) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`campanha/credito/consultar-participacao-por-id/${idDistribuidor}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Erro ao consultar campanha do distribuidor`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Erro ao consultar campanha do distribuidor: [${ex}]`
            };
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`campanha/credito/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Erro ao obter campanha de credito`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Erro ao obter campanha de credito: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, campanhaCreditoRequest) {

        try {
            let campanhaCreditoRequestFormated = campanhaCreditoFormatServer(JSON.parse(JSON.stringify(campanhaCreditoRequest)));

            const result = await Vue.prototype.$spTechApi.post(`campanha/credito/create-update`, campanhaCreditoRequestFormated,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Registro atualizado com sucesso!";

                if (campanhaCreditoRequest.id === 0) {
                    msg = "Registro criado com sucesso!";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: `${i18n.t('erroCriarAtualizarRegistro')}`
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `${i18n.t('erroCriarAtualizarRegistro')}: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `${i18n.t('erroCriarAtualizarRegistro')}: [${error}]`
                };
            }
        }
    },

    async Delete(_, id) {

        try {
            const result = await Vue.prototype.$spTechApi.delete(`campanha/credito/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                return {
                    success: true,
                    message: "Registro excluído com sucesso!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao excluir registro: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao excluir registro: [${error}]`
                };
            }
        }
    },

    async AlterarStatus(_, { id, statusId }) {

        try {
            const result = await Vue.prototype.$spTechApi.put(`campanha/credito/update-status/${id}/${statusId}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Perfil atualizado com sucesso!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao atualizar o status!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao atualizar o status: [${ex}]`
            };
        }
    },

    async ConsultarSaldoCampanhaTotvs(_, idDistribuidor) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`campanha/credito/saldo-campanha/${idDistribuidor}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Erro ao consultar campanha do distribuidor`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Erro ao consultar campanha do distribuidor: [${ex}]`
            };
        }
    },

    async ListarPedidosCampanhaTotvs(_, idDistribuidor) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`campanha/credito/list-pedidos-campanha/${idDistribuidor}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Erro ao consultar lista de pedidos da campanha`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Erro ao consultar lista de pedidos da campanha: [${ex}]`
            };
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.campanhaCredito, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.campanhaCredito;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
