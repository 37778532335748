<template>
    <v-app-bar 
        app 
        style="border-bottom: solid 1px var(--color__cinza) !important;"
    >

        <v-app-bar-nav-icon
            class="esconderMobile"
            @click.stop="openCloseSideMenu" style="border: none;padding:10px; margin-top: 10px;"
        >
            <v-icon 
                color="var(--color__status_inactive)"
                v-if="$store.state.menuSide"
            >
                mdi-playlist-remove
            </v-icon>
            <v-icon 
                color="var(--color__status_inactive)"
                v-else
            >
                mdi-playlist-plus
            </v-icon>
        </v-app-bar-nav-icon>

        <v-spacer></v-spacer>

        <div v-if="showFlags()" style="display: contents; width: 100px;">
            <v-img
                v-if="getLanguage() == 'pt'"
                :src="require('@/assets/images/brasil-flag.png')" max-height="40" max-width="40" 
                class="custom-icon"
                style="margin-right: 10px;"
            />
            <v-img
                v-else
                :src="require('@/assets/images/brasil-flag-disabled.png')" max-height="40" max-width="40" 
                class="custom-icon"
                style="margin-right: 10px;"
                :disabled="true"
            />

            <v-img
                v-if="getLanguage() == 'es'"
                :src="require('@/assets/images/espanha-flag.png')" max-height="40" max-width="40" 
                class="custom-icon"
                style="margin-right: 10px;"
            />
            <v-img
                v-else
                :src="require('@/assets/images/espanha-flag-disabled.png')" max-height="40" max-width="40" 
                class="custom-icon"
                style="margin-right: 10px;"
                :disabled="true"
            />
        </div>

        <v-btn 
            icon
            :to="{ name: 'carrinho' }"
        >
            <v-badge
                :content="listCarrinho.length.toString()"
            >
                <v-icon>mdi-cart</v-icon>
            </v-badge>
        </v-btn>

        <v-menu offset-y :nudge-width="250" closeOnClick closeOnContentClick>
            
            <template v-slot:activator="{ on }">
                <div style="padding:10px;">
                    <v-btn fab icon outlined v-on="on">
                        <v-icon>mdi-account</v-icon>
                    </v-btn>
                </div>
            </template>

            <v-list class="pa-0" color="#424242">
                <v-list-item 
                    v-if="userLoggedGetters.usuarioInterno == 1"
                    style="height: 100px; text-align: center;"
                >
                    <v-list-item-title>
                        <span style="font-size: 13px; color: silver"> {{ userLoggedGetters.perfil.nome }} </span>
                        <br />
                        <br />
                        <span style="font-size: 15px;"> {{ userLoggedGetters.nome }} </span>
                        <br />
                        <span style="font-size: 15px;"> {{ userLoggedGetters.email }} </span>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item 
                    v-else
                    style="height: 100px; text-align: center;"
                >
                    <v-list-item-title>
                        <span style="font-size: 13px; color: silver"> {{ userLoggedGetters.distribuidorSelected.nameProcessed }} </span>
                        <br />
                        <br />
                        <span style="font-size: 15px;"> {{ userLoggedGetters.nome }} </span>
                        <br />
                        <span style="font-size: 15px;"> {{ userLoggedGetters.email }} </span>
                    </v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item to="/usuario/minhasInformacoes" ripple="ripple" rel="noopener">
                    <v-list-item-title>
                        <v-icon left>mdi-account-tie</v-icon>
                        {{ $t('minhasInformacoes') }}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item to="/usuario/alterarSenha" ripple="ripple" rel="noopener">
                    <v-list-item-title>
                        <v-icon left>mdi-lock-outline</v-icon>
                        {{ $t('alterarSenha') }}
                    </v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item to="/logout" color="red" ripple="ripple" rel="noopener">
                    <v-list-item-title>
                        <v-icon left>mdi-logout</v-icon>
                        {{ $t('sair') }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>

    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';

    export default {

        mixins: [Helpers],

        data: () => ({

            listCarrinho: []
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),
            ...mapGetters('carrinhoModule', { carrinhoStateGetters: 'carrinhoState' }),
        },

        watch: {
            "carrinhoStateGetters.reload"() {
                this.atualizarCarrinho();
            }
        },

        methods: {
            openCloseSideMenu() {
                var menuSide = !this.$store.state.menuSide;
                this.openSideMenu(menuSide)
            },
            
            async atualizarCarrinho() {
                let response = await this.$store.dispatch("carrinhoModule/List");
                
                if (response != null && response != undefined) {
                    this.listCarrinho = response;
                }
            }
        },

        created() {
            this.atualizarCarrinho();
        }
    };
</script>

<style scoped>
    .esconderMobile {
        display: none;
    }

    @media only screen and (max-width: 1290px) {
        .esconderMobile {
            display: block;    
        }
    }
</style>