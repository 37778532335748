var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","lg":"8","md":"8","sm":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('produto')))])]),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"6","lg":"4","md":"4","sm":"6"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":{ name: 'produtoForm', params: {id: 0} }}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('novo'))+" "+_vm._s(_vm.$t('produto'))+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('novo'))+" "+_vm._s(_vm.$t('produto')))])])],1)],1),_c('v-row',{staticStyle:{"height":"70px"}},[_c('v-col',{attrs:{"cols":"6","lg":"11","md":"10","sm":"8"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.getRegisters($event)}}},[_c('v-text-field',{attrs:{"label":_vm.$t('pesquisaRapida'),"append-outer-icon":"mdi mdi-magnify","single-line":"","outlined":""},on:{"click":function($event){_vm.options.page=1;},"click:append-outer":_vm.getRegisters},model:{value:(_vm.filter.fastSearch),callback:function ($$v) {_vm.$set(_vm.filter, "fastSearch", $$v)},expression:"filter.fastSearch"}})],1)],1),_c('v-col',{staticStyle:{"text-align":"right","margin-top":"10px"},attrs:{"cols":"6","lg":"1","md":"2","sm":"4"}},[_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"color":"gray !important"},attrs:{"color":"white","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.gerarExcel}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-excel")]),_vm._v(" "+_vm._s(_vm.$t('gerarExcel'))+" ")],1)],1),_c('v-list-item',{on:{"click":_vm.gerarExcelCodigoFechado}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-export-outline")]),_vm._v(" "+_vm._s(_vm.$t('exportarCodigoFechado'))+" ")],1)],1),_c('v-list-item',{on:{"click":_vm.importarExcelCodigoFechado}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-database-import-outline")]),_vm._v(" "+_vm._s(_vm.$t('importarCodigoFechado'))+" ")],1)],1),_c('v-list-item',{on:{"click":_vm.gerarExcelCodigoParametrizavel}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-export-outline")]),_vm._v(" "+_vm._s(_vm.$t('exportarCodigoParametrizavel'))+" ")],1)],1),_c('v-list-item',{on:{"click":_vm.importarExcelCodigoParametrizavel}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-database-import-outline")]),_vm._v(" "+_vm._s(_vm.$t('importarCodigoParametrizavel'))+" ")],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredSearch,"options":_vm.options,"loading":_vm.loading,"items-per-page":10,"no-data-text":_vm.$t('nenhumRegistroEncontrado'),"footer-props":{
                    'items-per-page-text':_vm.$t('registrosPorPagina'),
                    'items-per-page-all-text':_vm.$t('todos'),
                    pageText: '{0}-{1} de {2}'
                }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.statusDescription",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"outlined":"","dark":""}},[_vm._v(" "+_vm._s(item.statusDescription)+" ")])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('ActionList',{attrs:{"id":item.id,"link":'produtoForm',"showButtons":{
                            edit: true,
                            cancel: item.status === 1 ? true : false,
                            active: item.status === 0 ? true : false,
                            clonar: true
                        }},on:{"confirmCancel":_vm.confirmCancel,"confirmActive":_vm.confirmActive,"confirmClone":_vm.confirmClone}})]}}])})],1)],1),_c('ActionDialog',{attrs:{"showDialog":_vm.dialog.show,"headerTitle":_vm.dialog.headerText,"bodyText":_vm.dialog.bodyText,"params":_vm.dialog.params},on:{"methodConfirmToCall":_vm.dialog.methodConfirm,"update:showDialog":function($event){return _vm.$set(_vm.dialog, "show", $event)},"update:show-dialog":function($event){return _vm.$set(_vm.dialog, "show", $event)}}}),_c('UploadFile',{attrs:{"showUploadFile":_vm.dialogUploadFile.show,"idKey":_vm.dialogUploadFile.idKey,"reload":_vm.dialogUploadFile.reload,"acceptType":_vm.dialogUploadFile.acceptType,"typeAttachment":_vm.dialogUploadFile.typeAttachment,"typeDocument":_vm.dialogUploadFile.typeDocument,"multipleFiles":_vm.dialogUploadFile.multipleFiles,"showMsgConfirmation":_vm.dialogUploadFile.showMsgConfirmation,"msgConfirmation":_vm.dialogUploadFile.msgConfirmation},on:{"methodConfirmAfterUpload":_vm.dialogUploadFile.methodAfterUpload,"update:showUploadFile":function($event){return _vm.$set(_vm.dialogUploadFile, "show", $event)},"update:show-upload-file":function($event){return _vm.$set(_vm.dialogUploadFile, "show", $event)}}}),_c('DialogMessage',{attrs:{"showDialogMessage":_vm.dialogMessage.show,"headerTitle":_vm.dialogMessage.headerText,"headerColor":_vm.dialogMessage.headerColor,"headerFontColor":_vm.dialogMessage.headerFontColor,"bodyText":_vm.dialogMessage.bodyText,"useEmTag":_vm.dialogMessage.useEmTag,"widthDialog":_vm.dialogMessage.widthDialog},on:{"methodOKToCall":_vm.dialogMessage.methodOK,"update:showDialogMessage":function($event){return _vm.$set(_vm.dialogMessage, "show", $event)},"update:show-dialog-message":function($event){return _vm.$set(_vm.dialogMessage, "show", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }