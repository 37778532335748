<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>{{ $t('tipoProduto') }}</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="tipoProdutoRequest.status"
                        :label="$t('ativar')"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <label>{{ $t('grandeza') }}</label>
                    <v-combobox
                        v-model="tipoProdutoRequest.grandezaSelected"
                        :items="listGrandeza"
                        item-text="descricao"
                        item-value="id"
                        clearable
                        outlined
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="6"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <label>{{ $t('descricao') }}</label>
                    <v-text-field
                        v-if="lang == 'pt'"
                        v-model="tipoProdutoRequest.descricao_Pt" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                        <template #prepend-inner v-if="showFlags()">
                            <v-img
                                :src="require('@/assets/images/brasil-flag.png')" max-height="25" max-width="25" 
                                class="custom-icon"
                                @click="switchLang('es')"
                            />
                        </template>
                    </v-text-field>
                    <v-text-field
                        v-else
                        v-model="tipoProdutoRequest.descricao_Es" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                        <template #prepend-inner v-if="showFlags()">
                            <v-img
                                :src="require('@/assets/images/espanha-flag.png')" max-height="25" max-width="25" 
                                class="custom-icon"
                                @click="switchLang('pt')"
                            />
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <label>{{ $t('cambio') }}</label>
                    <v-combobox
                        v-model="tipoProdutoRequest.cambioSelected"
                        :items="listCambio"
                        item-text="descricao"
                        item-value="id"
                        clearable
                        outlined
                    ></v-combobox>
                </v-col>
                <v-col cols="6"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <label>{{ $t('marca') }}</label>
                    <v-combobox
                        v-model="tipoProdutoRequest.marcaSelected"
                        :items="listMarca"
                        item-text="descricao"
                        item-value="id"
                        clearable
                        outlined
                    ></v-combobox>
                </v-col>
            </v-row>
            
            <br />

            <ActionButtons
                :request="tipoProdutoRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        data: () => ({

            validForm: true,
            lang: 'pt',

            tipoProdutoRequest: {
                id: 0,
                descricao: "",
                descricao_es: "",
                idCambio: 0,
                cambioSelected: null,
                idMarca: 0,
                marcaSelected: null,
                status: 1
            },

            listGrandeza: [],
            listCambio: [],
            listMarca: [],

            loading: false,
            
            validations: {
                required: required,
            }
        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {
            
            async getRegister() {

                if (this.id !== 0) {

                    const response = await this.$store.dispatch("tipoProdutoModule/GetById", this.id);

                    if (response.success) {
                        this.tipoProdutoRequest = response.result;
                    }

                    this.lang = this.getLanguage();
                }
            },

            async getLists() {
                
                this.listCambio = await this.$store.dispatch("cambioModule/List");
                this.listGrandeza = await this.$store.dispatch("grandezaModule/List");
                this.listMarca = await this.$store.dispatch("marcaModule/List");
            },

            async cancel() {
                this.$router.push({ path: "/produto/tipoProdutoList" });
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", this.$t('aviso'), this.$t('inconsistenciaFormulario'));
                    return false;
                }
                else {

                    this.loading = true;
                    this.showLoading();

                    if (this.tipoProdutoRequest.status === null) {
                        this.tipoProdutoRequest.status = 0;
                    }


                    this.tipoProdutoRequest.id = this.id;
                    this.tipoProdutoRequest.descricao = this.tipoProdutoRequest.descricao_Pt;
                    this.tipoProdutoRequest.idGrandeza = this.tipoProdutoRequest.grandezaSelected.id;
                    this.tipoProdutoRequest.idCambio = this.tipoProdutoRequest.cambioSelected.id;
                    this.tipoProdutoRequest.idMarca = this.tipoProdutoRequest.marcaSelected.id;

                    const result = await this.$store.dispatch("tipoProdutoModule/CreateUpdate", this.tipoProdutoRequest);

                    if (result.success === true) {
                        this.showToast("success", this.$t('sucesso'), result.message);
                        this.cancel();
                    }
                    else {
                        this.showToast("error", this.$t('aviso'), result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            },

            switchLang(lang) {
                this.lang = lang;
            }
        },

        created() {
            this.showLoading();

            this.getLists();
            this.getRegister();

            this.hideLoading();
        }
    })
</script>
