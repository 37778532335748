
import Vue from 'vue'
import i18n from '@/locales/_i18n';

import { produtoFormatServer, produtoFormatFront } from "./produtoFormatServer";

const getDefaultState = () => {
    return {
        produto: {
            id: 0,
            codigo: "",
            descricao: "",
            idTipoProduto: 0,
            idUnidadeMedida: 0,
            status: ""
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List() {

        try {
            const result = await Vue.prototype.$spTechApi.get("produto/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const listProduto = result.data.result;

                return listProduto;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar produtos: [${ex}]`
            };
        }
    },

    async ListarProdutoBase(_, listaProdutoBaseFilter) {

        try {
            const result = await Vue.prototype.$spTechApi.post("produto/lista-produto-base", listaProdutoBaseFilter,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listProdutoBase = result.data.result;

                return listProdutoBase;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar lista de produtos: [${ex}]`
            };
        }
    },

    async ListarParametrosByTipoProduto(_, listaProdutoBaseFilter) {

        try {
            const result = await Vue.prototype.$spTechApi.post("produto/lista-parametros-by-tipoproduto", listaProdutoBaseFilter,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listParametros = result.data.result;

                return listParametros;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar lista de parâmetros: [${ex}]`
            };
        }
    },

    async ListarFamilias(_, listaProdutoBaseFilter) {

        try {
            const result = await Vue.prototype.$spTechApi.post("produto/listar-familias", listaProdutoBaseFilter,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listFamilias = result.data.result;

                return listFamilias;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar lista de famílias: [${ex}]`
            };
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`produto/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Erro ao obter produto`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Erro ao obter produto: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, produtoRequest) {

        try {
            let produtoRequestFormatted = produtoFormatServer(produtoRequest);

            const result = await Vue.prototype.$spTechApi.post(`produto/create-update`, produtoRequestFormatted,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Registro atualizado com sucesso!";

                if (produtoRequest.id === 0) {
                    msg = "Registro criado com sucesso!";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: `${i18n.t('erroCriarAtualizarRegistro')}`
                };
            }
        }
        catch(error) {

            produtoFormatFront(produtoRequest);

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `${i18n.t('erroCriarAtualizarRegistro')}: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `${i18n.t('erroCriarAtualizarRegistro')}: [${error}]`
                };
            }
        }
    },

    async AlterarStatus(_, { id, statusId }) {

        try {
            const result = await Vue.prototype.$spTechApi.put(`produto/update-status/${id}/${statusId}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Produto atualizada com sucesso!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao atualizar o status!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao atualizar o status: [${ex}]`
            };
        }
    },

    async ListTreeView(_, { idGrandeza, idMarca, idTipoProduto }) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`produto/list-tree-view/${idGrandeza}/${idMarca}/${idTipoProduto}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listProduto = result.data.result;

                return listProduto;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar arvore de produtos: [${ex}]`
            };
        }
    },

    async Clonar(_, id) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`produto/clonar/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                return {
                    id: result.data.result,
                    success: true,
                    message: "Produto clonado com sucesso"
                };
            }
            else {
                return {
                    success: false,
                    result: `Erro ao clonar produto`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Erro ao clonar produto: [${ex}]`
            };
        }
    },

    async ConsultarImpostos(_, impostoRequest) {

        try {
            const result = await Vue.prototype.$spTechApi.post(`produto/consultar-impostos`, impostoRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const impostoResult = result.data.result;

                return impostoResult;
            }
            else {
                return {};
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Erro ao consultar impostos: [${ex}]`
            };
        }
    },

    async SolicitarCadastro(_, codigoProduto) {

        try {
            const result = await Vue.prototype.$spTechApi.post("produto/solicitar-cadastro", codigoProduto,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                return {
                    id: result.data.result,
                    success: true,
                    message: "Solicitação de cadastro efetuado com sucesso."
                };
            }
            else {
                return {
                    success: false,
                    result: `Erro ao efetuar solicitação de cadastro do produto`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar lista de parâmetros: [${ex}]`
            };
        }
    },

    async ListHistorico(_, idProduto) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`produto/list-historico/${idProduto}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listProdutoHistorico = result.data.result;

                return listProdutoHistorico;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar histórico do produto: [${ex}]`
            };
        }
    },

    async ImportarPlanilha(_, produtoImportarRequest) {

        try {

            const result = await Vue.prototype.$spTechApi.post(`produto/importar-excel`, produtoImportarRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                if (result.data) {
                    const log = result.data.result;
    
                    return log;
                }
                else {
                    return "";
                }
            }
            else {
                return {
                    success: false,
                    message: "Erro ao importar planilha!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Erro ao importar planilha: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Erro ao importar planilha: [${error}]`
                };
            }
        }
    },

    async ConsultarEstoque(_, consultaEstoqueRequest) {

        try {
            const result = await Vue.prototype.$spTechApi.post(`produto/consultar-estoque`, consultaEstoqueRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const consultaEstoqueResult = result.data.result;

                return consultaEstoqueResult;
            }
            else {
                return {};
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Erro ao consultar estoque: [${ex}]`
            };
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.produto, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.produto;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
