<template>
    <div class="text-center">
		<v-dialog
			v-model="showDialogMessage"
			transition="dialog-top-transition"
			persistent
			:width="widthDialog"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" :style="`border-bottom: solid 1px var(--color__cinza); background-color: ${headerColor} !important; color: ${headerFontColor} !important;`">
					<v-col 
						cols="10"
						lg="9"
						md="9"
						sm="10"
						style="text-align: left;"
					>
						{{ headerTitle }}
					</v-col>
					
					<v-col 
						v-if="useEmTag == true"
						cols="2"
						lg="3"
						md="3"
						sm="2"
						style="text-align: right;"
					>
					
						<v-tooltip left>
							<template v-slot:activator="{ on }">
								<v-btn
									v-on="on"
									class="mx-2"
									outlined
									@click="copiarLog()"
								>
									<v-icon small>mdi-content-copy</v-icon>
								</v-btn>
							</template>
							<span>Copiar para área de transferência</span>
						</v-tooltip>
		
					</v-col>
				</v-card-title>

				<v-card-text 
					v-if="useEmTag == false"
					style="margin-top: 20px; text-align: center;"
				>
					<strong v-html="bodyText"></strong>
				</v-card-text>

				<v-card-text 
					v-else
					style="margin-top: 20px;"
				>
					<pre>{{ bodyText }}</pre>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2"
							outlined
							@click="OK()"
						>
							OK
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

	import Helpers from '@/utilities/Helpers';

    export default {
		
        mixins: [Helpers],

        props: {

			showDialogMessage: {
				default: false
			},

			headerTitle: {
				type: String,
				default: ""
			},

			headerColor: {
				type: String,
				default: "#ffffff"
			},

			headerFontColor: {
				type: String,
				default: "black"
			},

			bodyText: {
				type: String,
				default: ""
			},

			useEmTag: {
				type: Boolean,
				default: false
			},

			widthDialog: {
				type: String,
				default: "500"
			},
		},

        methods: {

            OK() {
                this.$emit('methodOKToCall');
                this.$emit('update:showDialogMessage', false)
            },

			copiarLog() {
				navigator.clipboard.writeText(this.bodyText).then(() => {
                    this.showToast("success", this.$t('sucesso'), "Copiado com sucesso!");
				}).catch((erro) => {
                    this.showToast("error", this.$t('erro'), `Erro ao copiar: [${erro}]`);
				});
			}
        }
    };
</script>
