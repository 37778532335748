
import Vue from 'vue'
import i18n from '@/locales/_i18n';

const getDefaultState = () => {
    return {
        grandeza: {
            id: 0,
            descricao: "",
            status: ""
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List() {

        try {
            const result = await Vue.prototype.$spTechApi.get("produto/grandeza/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listGrandeza = result.data.result;

                return listGrandeza;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar grandezas: [${ex}]`
            };
        }
    },

    async ListOnlyActive() {

        try {
            const result = await Vue.prototype.$spTechApi.get("produto/grandeza/list-actives",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listGrandeza = result.data.result;

                return listGrandeza;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao carregar grandezas: [${ex}]`
            };
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$spTechApi.get(`produto/grandeza/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Erro ao obter grandeza`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Erro ao obter grandeza: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, grandezaRequest) {

        try {
            const result = await Vue.prototype.$spTechApi.post(`produto/grandeza/create-update`, grandezaRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Registro atualizado com sucesso!";

                if (grandezaRequest.id === 0) {
                    msg = "Registro criado com sucesso!";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: `${i18n.t('erroCriarAtualizarRegistro')}`
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `${i18n.t('erroCriarAtualizarRegistro')} [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `${i18n.t('erroCriarAtualizarRegistro')} [${error}]`
                };
            }
        }
    },

    async AlterarStatus(_, { id, statusId }) {

        try {
            const result = await Vue.prototype.$spTechApi.put(`produto/grandeza/update-status/${id}/${statusId}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Grandeza atualizada com sucesso!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Erro ao atualizar o status!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Erro ao atualizar o status: [${ex}]`
            };
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.grandeza, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.grandeza;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
