var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"7","lg":"10","md":"10","sm":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('pedidoPorStatus')))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"11","md":"10","sm":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t('status')))]),_c('v-combobox',{attrs:{"items":_vm.listStatus,"item-text":"statusDescricao","item-value":"id","clearable":"","outlined":""},on:{"change":function($event){return _vm.getRegisters()}},model:{value:(_vm.statusSelected),callback:function ($$v) {_vm.statusSelected=$$v},expression:"statusSelected"}})],1),_c('v-col',{staticStyle:{"display":"flex","align-content":"center","align-items":"center"},attrs:{"cols":"12","lg":"1","md":"2","sm":"12"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"exportButtons"},[_c('v-btn',_vm._g({staticClass:"btnActions",staticStyle:{"width":"45px","border":"none !important"},attrs:{"outlined":"","color":"var(--color__main) !important","small":""},on:{"click":function($event){return _vm.exportar()}}},on),[_c('v-icon',_vm._g({attrs:{"large":""}},on),[_vm._v("mdi mdi-file-excel-outline")])],1),_c('label',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.$t('exportar')))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('exportarExcel')))])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"options":_vm.options,"loading":_vm.loading,"items":_vm.listRelatorio,"items-per-page":30,"no-data-text":_vm.$t('nenhumRegistroEncontrado'),"footer-props":{
                    'items-per-page-text':_vm.$t('registrosPorPagina'),
                    'items-per-page-all-text':_vm.$t('todos'),
                    pageText: '{0}-{1} de {2}'
                }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.statusDescricao",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"outlined":"","dark":""}},[_vm._v(" "+_vm._s(item.statusDescricao)+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }