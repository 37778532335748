<template>
    <div class="text-center">
		<v-dialog
			v-model="showDialogPedidosCampanha"
			transition="dialog-top-transition"
			persistent
			width="700"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					Pedidos de Campanha
				</v-card-title>

				<v-card-text style="margin-top: 20px; text-align: center;">
						
					<v-row>
						<v-col 
							cols="12"
							lg="12"
							md="12"
							sm="12"
						>
							<label>Saldo Total: </label>
							<label style="color: var(--color__main) !important; font-size: 20px;">{{ valorTotal }} </label>
						</v-col>
					</v-row>
						
					<v-row>
						<v-col 
							cols="12"
							lg="12"
							md="12"
							sm="12"
						>
							<v-data-table
								:headers="headers"
								:loading="loading"
								:items="listPedidosSaldoCampanha"
								class="elevation-1"
								no-data-text="Não há pedidos com saldo de campanha"
								:footer-props="{
									'items-per-page-text':$t('registrosPorPagina'),
									'items-per-page-all-text':$t('todos'),
									pageText: '{0}-{1} de {2}'
								}"
							>
							</v-data-table>
						</v-col>
					</v-row>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 btn-plus botaoDispensar"
							outlined
							@click="dispensar()"
						>
							FECHAR
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
	import { formaterDecimalBR } from '@/utilities/Utils';

    export default {

        mixins: [Helpers],

        props: {

			idAux: {
				type: Number,
				default: 0
			},

			showDialogPedidosCampanha: {
				default: false
			},

			idDistribuidor: {
				type: String,
				default: "0"
			},
		},
		
        data: vue => ({
			listPedidosSaldoCampanha: [],
			valorTotal: "R$ 0,00",

            og: Math.pow(10, 2),
			
			loading: false,
            headers: [
                { text: vue.$t('numeroPedido'), value: "pedido", sortable: true, size: "5%" },
                { text: vue.$t('saldo'), value: "valorFormatted", sortable: true}
            ],
		}),

		watch: {
			"idAux"() {
				this.limparDados();
				this.carregarSaldoCampanha();
			}
		},

        methods: {

            dispensar() {
				this.$emit('update:showDialogPedidosCampanha', false)
            },

			async limparDados() {
				this.valorTotal = "...";
				this.listPedidosSaldoCampanha = [];
			},

            async carregarSaldoCampanha() {

				this.loading = true;
				
				const resultSaldoTotal = await this.$store.dispatch("campanhaCreditoModule/ConsultarSaldoCampanhaTotvs", this.idDistribuidor);
				this.valorTotal = `${this.$t('simboloMoeda')} ${formaterDecimalBR( resultSaldoTotal.result)}`;

				const result = await this.$store.dispatch("campanhaCreditoModule/ListarPedidosCampanhaTotvs", this.idDistribuidor);

				this.listPedidosSaldoCampanha = [];

				if (result.success) {
					this.listPedidosSaldoCampanha = result.result;

					this.loading = false;
				}				
            }
        }
    };
</script>
